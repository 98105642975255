import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/alexa'
  },
  {
    path: '/alexa',
    name: 'Alexa',
    component: () => import(/* webpackChunkName: "about" */ 'views/alexa/Alexa'),
    meta: { title: 'Alexa' }
  },
  {
    path: '/wirelessconfiguration',
    name: 'WirelessConfiguration',
    component: () => import(/* webpackChunkName: "about" */ 'views/wirelessconfiguration/WirelessConfiguration'),
    meta: { title: 'Wireless Configuration' }
  },
  {
    path: '/tplink',
    name: 'TpLink',
    component: () => import(/* webpackChunkName: "about" */ 'views/tplink/TpLink'),
    meta: { title: 'TP-LINK Router' }
  },
  {
    path: '/asus',
    name: 'Asus',
    component: () => import(/* webpackChunkName: "about" */ 'views/asus/Asus'),
    meta: { title: 'Asus Router' }
  },
  {
    path: '/netgear',
    name: 'Netgear',
    component: () => import(/* webpackChunkName: "about" */ 'views/netgear/Netgear'),
    meta: { title: 'Netgear Router' }
  },
  {
    path: '/dlink',
    name: 'DLink',
    component: () => import(/* webpackChunkName: "about" */ 'views/dlink/DLink'),
    meta: { title: 'D-LINK Router' }
  },
  {
    path: '/assistant',
    name: 'Assistant',
    component: () => import(/* webpackChunkName: "about" */ 'views/assistant/Assistant'),
    meta: { title: 'Google Assistant' }
  },
  {
    path: '/questions',
    name: 'Questions',
    component: () => import(/* webpackChunkName: "about" */ 'views/questions/Questions'),
    meta: { title: 'FAQ' }
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about" */ 'views/aboutus/AboutUs'),
    meta: { title: 'About Us' }
  },
  {
    path: '/termservice',
    name: 'TermService',
    component: () => import(/* webpackChunkName: "about" */ 'views/termservice/TermService'),
    meta: { title: 'Term of Service' }
  },
  {
    path: '/userlisence',
    name: 'UserLisence',
    component: () => import(/* webpackChunkName: "about" */ 'views/userlisence/UserLisence'),
    meta: { title: 'End User License Agreement' }
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "about" */ 'views/privacypolicy/PrivacyPolicy'),
    meta: { title: 'Privacy policy' }
  },
  {
    path: '/datapolicy',
    name: 'DataPolicy',
    component: () => import(/* webpackChunkName: "about" */ 'views/datapolicy/DataPolicy'),
    meta: { title: 'Data policy' }
  },
  {
    path: '/instruction',
    name: 'Instruction',
    component: () => import(/* webpackChunkName: "about" */ 'views/instruction/Instruction'),
    meta: { title: 'Instruction' }
  },
  {
    path: '/safe',
    name: 'Safe',
    component: () => import(/* webpackChunkName: "about" */ 'views/video/Safe'),
    meta: { title: 'Safe' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
