<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default ({
  name: 'app',
  components: { 
    
  },
})
</script>

<style>
  @import "assets/css/base.css";
  @import "assets/css/font.css";
</style>
